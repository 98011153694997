import React, { useState, useEffect } from 'react';
import './header.css';
import './change.css'
import { FaSearch } from "react-icons/fa";
// import user from '../../assets/user 1.png';
import logo from '../../assets/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { MENU_ITEM } from '../../utils/constant';
import { useGetAllCategoriesQuery } from '../../redux/apiSlice';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import india from '../../assets/india.png';
import australia from '../../assets/AUSTELIA.png';
import uk from '../../assets/uk flag.png';

const countries = [
    { code: 'IN', name: 'IN', flag: india },
    { code: 'AU', name: 'Aus', flag: australia },
    { code: 'GB', name: 'UK', flag: uk },
    // Add more countries as needed
];

const languages = [
    'English', 'Spanish', 'French', 'German', 'Chinese'
    // Add more languages as needed
];

const Header = () => {
    useGetAllCategoriesQuery();
    const location = useLocation();
    const [profileOpen, setProfileOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu toggle
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
    
    const cart = useSelector((state) => state.cart);
    // const categories = useSelector((state) => state.categories);

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
    };

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
    };

    const handleToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleCloseMenu = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    const handleClickOutside = (event) => {
        const toggleButton = document.querySelector('.navbar-toggler');
        const collapseMenu = document.querySelector('#navbarSupportedContent');
        if (collapseMenu && !collapseMenu.contains(event.target) && !toggleButton.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className='bg-white px-2 sticky-top mainnavbar'>
            <nav className="bg-white navbar navbar-expand-lg p-2 p-xl-0">
                <div className="container-fluid justify-content-between align-items-center p-0">
                    <Link className="navbar-brand d-none d-lg-block" to="/"><img src={logo} alt="Logo" /></Link>
                    <button className="navbar-toggler" type="button" onClick={handleToggle} aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen} aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Link className="navbar-brand d-block d-lg-none me-0" to="/"><img src={logo} alt="Logo" width={90} /></Link>
                    <div className='text-black d-flex d-lg-none align-items-center'>
                        {/* <div className="search-container me-3 col">
                            <div className="search-iconbox  bg-transparent" style={{ marginLeft: '12px' }}>
                                <i className="bi bi-search text-dark position-absolute" style={{ fontSize: '15px', marginBottom: '1px', top: '7px' }}></i>
                            </div>
                            <input type="text" className="search-input d-none" placeholder="Search..." />
                        </div> */}
                        <div className="search-container me-4">
                            <div className="search-iconbox  bg-transparent" style={{ marginRight: '15px' }}>
                                <i className="bi bi-search text-dark position-absolute" style={{ fontSize: '15px', marginBottom: '1px', top: '7px', cursor: 'pointer' }}></i>
                            </div>
                            <input type="text" className="search-input d-none" placeholder="Search..." />
                        </div>
                        <div className="dropdown">
                            <button onClick={() => setProfileOpen(!profileOpen)} className=" bg-transparent border-0 account_sign text-start" type="button" data-bs-toggle="dropdown" aria-expanded={profileOpen}>
                                <i className="bi bi-person fs-4"></i>
                            </button>
                            <ul className={`dropdown-menu dropdown-menu2 ${profileOpen ? 'show' : 'd-none'}`}>
                                <li><a className="dropdown-item" href="#">Your Account</a></li>
                                <li><a className="dropdown-item" href="#">Tracking Order</a></li> 
                                <li><a className="dropdown-item" href="#">Profile Edit</a></li>  
                                <li><a className="dropdown-item" href="/login">Login & Security</a></li>
                            </ul>
                        </div>
                        <Link to="/cart" className='d-flex align-items-center ms-2 position-relative' onClick={handleCloseMenu}>
                            <i className="bi bi-bag text-dark fs-5" style={{ marginBottom: '2px' }}></i>
                            <span className="cart_message position-absolute start-100 translate-middle border border-light rounded-circle">
                                <span>{cart?.items && cart?.items?.length > 0 ? cart?.items?.length : 0}</span>
                            </span>
                        </Link>
                    </div>
                    <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">

                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/' ? 'text-primary' : 'text-dark'}`} to="/" onClick={handleCloseMenu}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/ourcollection/669ab4035641fc4867db9d91' ? 'text-primary' : 'text-dark'}`} to="/ourcollection/669ab4035641fc4867db9d91" onClick={handleCloseMenu}>Girls</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/ourcollection/669ab41f5641fc4867db9d99' ? 'text-primary' : 'text-dark'}`} to="/ourcollection/669ab41f5641fc4867db9d99" onClick={handleCloseMenu}>Boys</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/ourcollection/669ab4035641fc4867db9d91'}`} to="/" onClick={handleCloseMenu}>Twinning Sets</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/ourcollection/669ab4035641fc4867db9d91'}`} to="/" onClick={handleCloseMenu}>Blog</Link>
                            </li>
                            {/* {categories?.data?.map((category) => (
                                <li className="nav-item" key={category._id}>
                                    <Link className={`nav-link ${location.pathname === `/ourcollection/${category._id}` ? 'text-primary' : 'text-dark'}`} to={`/ourcollection/${category._id}`} onClick={handleCloseMenu}>{category.name}</Link>
                                </li>
                            ))} */}
                        </ul>
                        <div className="d-block d-lg-none">
                            <div className="d-flex align-items-center flex-wrap justify-content-between">
                                <DropdownButton
                                    id="dropdown-country-button"
                                    title={<span><img src={selectedCountry.flag} alt={selectedCountry.name} style={{ width: '20px', height: '20px', marginRight: '8px' }} /> {selectedCountry.name}</span>}
                                    onSelect={(eventKey) => handleCountrySelect(countries.find(c => c.code === eventKey))}
                                    className="ps-0"
                                >
                                    {countries.map((country) => (
                                        <Dropdown.Item key={country.code} eventKey={country.code}>
                                            <img src={country.flag} alt={country.name} style={{ width: '20px', height: '20px', marginRight: '8px' }} className="ps-0" /> {country.name}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                                <DropdownButton
                                    id="dropdown-language-button"
                                    title={selectedLanguage}
                                    onSelect={(eventKey) => handleLanguageSelect(eventKey)}
                                    className="me-0"
                                >
                                    {languages.map((language) => (
                                        <Dropdown.Item key={language} eventKey={language}>
                                            {language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                                {/* <div className="search-container me-3">
                                    <div className="search-iconbox  bg-transparent" style={{ marginLeft: '12px' }}>
                                        <i className="bi bi-search text-dark position-absolute" style={{ fontSize: '15px', marginBottom: '1px', top: '7px', cursor: 'pointer' }}></i>
                                    </div>
                                    <input type="text" className="search-input d-none" placeholder="Search..." />
                                </div> */}
                                {/* <div className="dropdown">
                                    <button onClick={() => setProfileOpen(!profileOpen)} className=" bg-transparent border-0 account_sign text-start" type="button" data-bs-toggle="dropdown" aria-expanded={profileOpen}>
                                        <i className="bi bi-person fs-4"></i>
                                    </button>
                                    <ul className={`dropdown-menu dropdown-menu2 ${profileOpen ? 'show' : 'd-none'}`}>
                                        <li><a className="dropdown-item" href="#">Your Account</a></li>
                                        <li><a className="dropdown-item" href="#">Tracking Order</a></li>
                                        <li><a className="dropdown-item" href="#">Free Catalog</a></li>
                                        <li><a className="dropdown-item" href="#">Profile Edit</a></li>
                                        <li><a className="dropdown-item" href="#">Personalize Design</a></li>
                                        <li><a className="dropdown-item" href="#">Custom Design</a></li>
                                        <li><a className="dropdown-item" href="/login">Login & Security</a></li>
                                    </ul>
                                </div>
                                <Link to="/cart" className='d-flex align-items-center ms-3 position-relative' onClick={handleCloseMenu}>
                                    <i className="bi bi-bag text-dark fs-5" style={{ marginBottom: '2px' }}></i>
                                    <span className="position-absolute start-100 translate-middle border border-light rounded-circle" style={{ top: '8px', background: '#b20570', padding: '0px 6px', color: '#fff', fontSize: '12px' }}>
                                        <span className="">0</span>
                                    </span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-lg-block">
                        <div className="d-flex align-items-center flex-wrap">
                            <div className="search-container me-0">
                                <div className="search-iconbox me-3">
                                    <FaSearch className="search-icon" />
                                </div>
                                <input type="text" className="search-input" placeholder="Search..." />
                            </div>
                            <DropdownButton
                                id="dropdown-country-button"
                                title={<span><img src={selectedCountry.flag} alt={selectedCountry.name} style={{ width: '20px', height: '20px', marginRight: '5px', marginBottom: '2px' }} /> {selectedCountry.name}</span>}
                                onSelect={(eventKey) => handleCountrySelect(countries.find(c => c.code === eventKey))}
                                className='ps-0'
                            >
                                {countries.map((country) => (
                                    <Dropdown.Item key={country.code} eventKey={country.code}>
                                        <img src={country.flag} alt={country.name} style={{ width: '20px', height: '20px', marginRight: '5px', marginBottom: '2px' }} /> {country.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                            <DropdownButton
                                id="dropdown-language-button"
                                title={selectedLanguage}
                                onSelect={(eventKey) => handleLanguageSelect(eventKey)}
                                className="me-2 pe-0"
                            >
                                {languages.map((language) => (
                                    <Dropdown.Item key={language} eventKey={language}>
                                        {language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                            <div className="dropdown">
                                <button onClick={() => setProfileOpen(!profileOpen)} className=" bg-transparent border-0 account_sign text-start me-1" type="button" data-bs-toggle="dropdown" aria-expanded={profileOpen}>
                                    <i className="bi bi-person fs-4"></i>
                                </button>
                                <ul className={`dropdown-menu ${profileOpen ? 'show' : 'd-none'}`}>
                                    <li><a className="dropdown-item" href="#">Your Account</a></li>
                                    <li><a className="dropdown-item" href="#">Tracking Order</a></li> 
                                    <li><a className="dropdown-item" href="#">Profile Edit</a></li>  
                                    <li><a className="dropdown-item" href="/login">Login & Security</a></li>
                                </ul>
                            </div>
                            <Link to="/cart" className='d-flex align-items-center mx-2 position-relative' onClick={handleCloseMenu}>
                                <i className="bi bi-bag text-dark fs-5" style={{ marginBottom: '2px' }}></i>
                                <span className="cart_message position-absolute start-100 translate-middle border border-light rounded-circle">
                                    <span className="cart_text" style={{}}>{cart?.items && cart?.items?.length > 0 ? cart?.items?.length : 0}</span>
                                </span>
                            </Link>
                            <Link to="/wishlist" className='d-flex align-items-center ms-3 position-relative' onClick={handleCloseMenu}>
                                <i className="bi bi-heart text-dark fs-5" style={{ marginBottom: '2px' }}></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>

            {profileOpen && (
                <ul className='customprofiledown mt-3 d-none'>
                    {MENU_ITEM.map((v) => (
                        <Link key={v.path} onClick={() => setProfileOpen(false)} className={`nav-link mt-2`} to={v.path}>{v.item}</Link>
                    ))}
                </ul>
            )}
        </header>
    );
};

export default Header;
