import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";


const Frequentlyaskedquestions = () => {
  return (
    <div className="frequentlyaskedquestions" >
      <div className="container">
        <div className="col-12 d-flex flex-wrap justify-content-center">
          <div className="faq-title title col-12 col-lg-4">
            <h4 className="text-center text-lg-start">Frequently Asked Questions</h4>
            <p className="text-center text-lg-start">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet.
            </p>
            {/* <button className="d-none d-lg-block">
              Ask A Question{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.72024 19.7374C7.2809 19.298 7.2809 18.5857 7.72024 18.1464L13.6747 12.1919L7.72024 6.23739C7.2809 5.79805 7.2809 5.08574 7.72024 4.6464C8.15958 4.20706 8.87189 4.20706 9.31123 4.6464L16.0612 11.3964C16.5006 11.8357 16.5006 12.548 16.0612 12.9874L9.31123 19.7374C8.87189 20.1767 8.15958 20.1767 7.72024 19.7374Z"
                  fill="white"
                />
              </svg>
            </button> */}
            <button type="button" class="buttons_alldata d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Ask A Question{" "}
              <MdOutlineKeyboardArrowRight className="icons" />
            </button>
            <>
              {/* Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Enter Your Question
                      </h1>
                      <button
                        type="button"
                        className="btn-close bg-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Question</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>

          </div>
          <div className="col-12 col-lg-8 mt-4 mt-lg-0 accordion-faq">
            <div className="accordion ms-0 ms-lg-4" id="accordionExample">
              <div className="accordion-item mb-4">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Accordion Item #1
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>This is the first item's accordion body.</strong> It
                    is shown by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div className="accordion-item  mb-4">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Accordion Item #2
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>This is the second item's accordion body.</strong>{" "}
                    It is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div className="accordion-item ">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Accordion Item #3
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It
                    is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="title mt-4">
            {/* <button className="d-block d-lg-none">
              Ask A Question{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.72024 19.7374C7.2809 19.298 7.2809 18.5857 7.72024 18.1464L13.6747 12.1919L7.72024 6.23739C7.2809 5.79805 7.2809 5.08574 7.72024 4.6464C8.15958 4.20706 8.87189 4.20706 9.31123 4.6464L16.0612 11.3964C16.5006 11.8357 16.5006 12.548 16.0612 12.9874L9.31123 19.7374C8.87189 20.1767 8.15958 20.1767 7.72024 19.7374Z"
                  fill="white"
                />
              </svg>
            </button> */}
            <button type="button" class="btn btn-primary d-block d-lg-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Ask A Question{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.72024 19.7374C7.2809 19.298 7.2809 18.5857 7.72024 18.1464L13.6747 12.1919L7.72024 6.23739C7.2809 5.79805 7.2809 5.08574 7.72024 4.6464C8.15958 4.20706 8.87189 4.20706 9.31123 4.6464L16.0612 11.3964C16.5006 11.8357 16.5006 12.548 16.0612 12.9874L9.31123 19.7374C8.87189 20.1767 8.15958 20.1767 7.72024 19.7374Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frequentlyaskedquestions;
