import React, { useState } from "react";
import { Tabs, Tab, Button, Form } from "react-bootstrap";
const ADDREVIEW = [
  {
    rating: 5,
  },
];
const Productreview = ({ description }) => {
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState("");

  const handleAddReview = () => {
    if (newReview.trim() !== "") {
      setReviews([...reviews, newReview]);
      setNewReview("");
    }
  };
  return (
    <div className="">

      <div className="container productreview p-1 mt-4">
        {/* <h4>Product Details</h4> */}
        <Tabs defaultActiveKey="description" id="product-tabs">
          <Tab eventKey="description" title="Description">
            <div dangerouslySetInnerHTML={{ __html: description }} className="mb-3"></div>
          </Tab>

          <Tab eventKey="reviews" title="Reviews">
            <div className="mt-3">
              {/* {reviews.length > 0 ? (
                reviews.map((review, index) => (
                  <div key={index} className="mb-2">
                    <p>{review}</p>
                  </div>
                ))
              ) : ( 
                <p>No reviews yet.</p>
              )} 
              <Button href="#login_modal" data-bs-toggle="modal" className="mt-2" onClick={handleAddReview}>
                Add Review
              </Button> */}
              <Form.Group controlId="newReview">
                <Form.Label>Add a Review</Form.Label>
                {ADDREVIEW.map((v, i) => (
                  <div key={i} className="col-12 col-lg-3 mb-4 mb-lg-0">
                    <div className="t-rating mt-2">
                      {[0, 1, 2, 3, 4].map((r, j) => {
                        return r < r.rating ? (
                          <i key={j} class="bi bi-star-fill text-warning me-1"></i>
                        ) : (
                          <i key={j} class="bi bi-star text-warning me-1"></i>
                        );
                      })}
                    </div>
                  </div>
                ))}
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newReview}
                  onChange={(e) => setNewReview(e.target.value)}
                  placeholder="Write your review here"
                  className="mt-4"
                />
              </Form.Group>
              <Button href="" data-bs-toggle="modal" className="mt-2">
                Save
              </Button> 

            </div>
          </Tab>
        </Tabs>


      </div>

      {/* modal */}
      <div className="modal fade" id="login_modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">

              <Form.Group controlId="newReview">
                <Form.Label>Add a Review</Form.Label>
                {ADDREVIEW.map((v, i) => (
                  <div key={i} className="col-12 col-lg-3 mb-4 mb-lg-0">
                    <div className="t-rating mt-2">
                      {[0, 1, 2, 3, 4].map((r, j) => {
                        return r < r.rating ? (
                          <i key={j} class="bi bi-star-fill text-warning me-1"></i>
                        ) : (
                          <i key={j} class="bi bi-star text-warning me-1"></i>
                        );
                      })}
                    </div>
                  </div>
                ))}
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newReview}
                  onChange={(e) => setNewReview(e.target.value)}
                  placeholder="Write your review here"
                  className="mt-4"
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Productreview;
