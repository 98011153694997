import React, { useEffect } from 'react'

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container">

            <div className="row ">
                <div className="col-12 py-5 px-5 text-capitalize">
                    <div className="title">
                        <h3>Privacy Policy</h3>
                    </div>
                    <div className="privacy_policy_paragraph mt-5">
                        <h6>Information We Collect</h6>
                        <p>
                        When you visit our website, make a purchase, or interact with us, we may collect the following types of information:
                        </p>
                        <p>Personal Information: Name, email address, contact details, shipping and billing address, and payment information.
Browsing Information: IP address, browser type, device information, and website usage data.
Cookies: We use cookies to improve your browsing experience and track site usage.</p>
                        <h5 className='mb-2 mb-lg-4 text-decoration-underline'>
                        How We Use Your Information
                        </h5>
                        <h6>
                        The information we collect is used for:
                        </h6>
                        <p>
                        Processing and delivering your orders.
Communicating with you regarding your orders, offers, and promotions.
Enhancing your browsing experience on our website.
Complying with legal requirements.
                        </p>
                        <h6>
                        Sharing of Information
                        </h6>
                        <p>
                        We do not sell, trade, or share your personal information with third parties except for the following circumstances:

Service Providers: We may share information with trusted service providers who assist us in operating our website, fulfilling orders, or conducting business.
Legal Compliance: We may disclose information when required by law.
                        </p>
                        <h6>
                            Data Security
                        </h6>
                        <p>
                        We are committed to safeguarding your personal information. We use industry-standard security measures to protect your data from unauthorized access, alteration, or disclosure.
                        </p>
                        <h6>
                        Your Rights
                        </h6>
                        <p>
                        You have the right to:

Access and review the personal information we hold about you.
Request corrections or updates to your information.
Opt-out of marketing communications at any time.
                        </p>
                        <h6>
                        Cookies and Tracking Technologies
                        </h6>
                        <p>
                        Our website uses cookies to enhance your experience. You can manage or disable cookies through your browser settings. However, please note that disabling cookies may affect your ability to use certain features of our website.
                        </p>
                        <h6>
                        Changes to This Privacy Policy
                        </h6>
                        <p>
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated effective date.
                        </p>
                        <h6>
                        Contact Us
                        </h6>
                        <p>
                        If you have any questions or concerns about our Privacy Policy, please contact us at:
                        </p>
                       
                        <h6>
                        Email: annecafashion06@gmail.com
                        </h6>
                        <h6>
                        Phone: +919427921383
                        </h6>
                        <h6>
                        Address: 30, 2nd floor, 2nd gali, new estate, near T.V Coumpund, opp pani tanki, Road No-6 udhna-Surat-394210
                        </h6> 


                    </div>

                </div>
            </div>
        </div>
    )
}

export default Privacy
