import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCategories, setError, setLoading } from './slices/categoriesSlice';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://anneca-backend.onrender.com/api/v1',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        signup: builder.mutation({
            query: (user) => ({
                url: '/auth/register',
                method: 'POST',
                body: user,
            }),
        }),
        login: builder.mutation({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                body: credentials,
            }),
            transformResponse: (response) => response,
        }),
        getProducts: builder.query({
            query: (category) => `/product/category/${category}`,
        }),
        getAllCategories: builder.query({
            query: () => 'category',
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    dispatch(setLoading());
                    const { data } = await queryFulfilled;
                    dispatch(setCategories(data?.data || []));
                } catch (error) {
                    dispatch(setError(error));
                }
            },
        }),
        getSingleProduct: builder.query({
            query: (id) => `/product/${id}`,
        }),
        getProductMeasurement: builder.query({
            query: (id) => `/measurements/${id}`,
            transformResponse: (response) => {
                const transformedMeasurements = response.data.measurements.map((measurement) => {
                    return Object.fromEntries(
                        Object.entries(measurement).filter(([key, value]) => value !== null)
                    );
                });
                return {
                    ...response,
                    data: {
                        ...response.data,
                        measurements: transformedMeasurements
                    }
                };
            },
        }),
        getSimilarProduct: builder.query({
            query: ({productId, categoryId}) => `product/similar/${categoryId}/${productId}`,
        }),
        getFeaturedProduct: builder.query({
            query: () => `product/featured`,
        })
    }),
});

export const { 
    useSignupMutation, 
    useLoginMutation, 
    useGetProductsQuery, 
    useGetAllCategoriesQuery, 
    useGetSingleProductQuery, 
    useGetProductMeasurementQuery,
    useGetSimilarProductQuery,
    useGetFeaturedProductQuery
} = apiSlice;
