import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import Productreview from "../productreview/productreview";
import Similarproducts from "../similarproducts/similarproducts";
import Frequentlyaskedquestions from "../frequentlyaskedquestions/frequentlyaskedquestions";
import Testimonial from "../testimonial/testimonial";
import ProductDisplay from "./productDisplay";
import { useGetSingleProductQuery } from "../../redux/apiSlice";
import { useParams } from "react-router-dom";
import Spinner from "../../utils/spinner";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../redux/slices/cartSlice";
import Productsimilar from "../productsimilar/productsimilar";
import MeasurementTable from "./SizeChartTable";

const ProductDetails = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();

  const { data: product, error, isLoading } = useGetSingleProductQuery(productId);
  const categoryId = useSelector((state) => state.categories?.data?.find((v) => v?.name === "Girls")?._id);

  const [sizeData, setSizeData] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);

  const images = product?.data?.images || [];
  const Video = useMemo(() => images?.find((v) => v?.url.includes("/video/")), [images]);

  useEffect(() => {
    const sizeExtract = product?.data.size?.[0].split(",");
    setSizeData(sizeExtract);
  }, [product?.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageHover = useCallback((index) => {
    setCurrentImageIndex(index);
  }, []);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleAddToCart = () => {
    setLoading(true); // Start loading
    setTimeout(() => {
      dispatch(
        addItem({
          id: productId,
          name: product?.data?.name,
          price: product?.data?.weight,
          quantity: quantity,
        })
      );
      setLoading(false); // End loading
    }, 2000); // Simulating a delay for the loader
  };

  if (isLoading) return <Spinner />;
  if (error) return <p>Error loading product</p>;

  return (
    <>
      <div className="mt-5 container product-slider d-flex flex-wrap align-items-start">
        <ProductDisplay images={images} currentImageIndex={currentImageIndex} handleImageHover={handleImageHover} />
        <div className="col-12 col-md-5 ms-0 ms-lg-0 main-collection">
          <div className="decs d-flex flex-wrap align-items-start">
            <h2 className="mb-2 d-flex align-items-start">{product?.data?.name}
              <i className="bi bi-heart fs-5" style={{ marginBottom: '0px', position: 'relative', top: '8px', left: '4px', color: 'gray', cursor: 'pointer' }}></i>

            </h2>
          </div>
          <div className="mb-3 pb-2 border-bottom">
            <div className="d-flex align-items-center">
              <div className="me-2">
                <span className="fs-4 fw-semibold">
                  <del>₹{product?.data?.price}</del>
                </span>
                <span className="fs-4 fw-semibold"> ₹{product?.data?.weight}</span>
              </div>
              <div>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <span className="ms-2">({product?.data?.reviews?.length} reviews)</span>
              </div>
            </div>
          </div>
          {
            Video?.url && <video width="150" controls autoPlay>
              <source src={Video?.url} type="video/mp4" />
            </video>
          }
          <div className="product-size mt-3 mb-2">
            <div className="size-title d-flex flex-wrap justify-content-between align-items-center">
              <h6 className="mb-0">Size</h6>
              <button
                className="btn p-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <u>Size Chart</u>
              </button>
            </div>
          </div>

          {/* Dropdown Select */}
          <select className="form-select" aria-label="Default select example">
            {sizeData?.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </select>

          <div className="mt-4 d-flex flex-wrap">
            <div className="col-12 col-md-3 mb-0">
              <div className="d-flex align-items-center">
                <Button variant="outline-secondary" className="me-2" onClick={handleDecrement}>
                  -
                </Button>
                <span className="fw-bold">{quantity}</span>
                <Button variant="outline-secondary" className="ms-2" onClick={handleIncrement}>
                  +
                </Button>
              </div>
            </div>
            <div className="col-12 col-md-9 mb-0 mt-4 mt-lg-0">
              <Button className="addtocart w-100" onClick={handleAddToCart} disabled={loading}>
                {loading ? (
                  <span className="d-flex align-items-center justify-content-center">
                    <div className="spinner-border spinner-border-sm text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </span>
                ) : (
                  "Add to Cart"
                )}
              </Button>
            </div>
          </div>

          <div className="mt-3">
            <Button variant="outline-dark" className="w-100">
              Buy Now
            </Button>
          </div>
          <div className="mt-4">
            <p className="mb-0">
              <i className="bi bi-truck"></i> Free worldwide shipping on all orders over ₹100
            </p>
            <p className="mb-0">
              <i className="bi bi-clock"></i> Delivers in: 3 Working Days Shipping & Return
            </p>
          </div>
          <Productreview description={product?.data?.description} />
        </div>
      </div>
      <div className="py-5 py-lg-5">
        <Productsimilar categoryId={categoryId} />
      </div>
      <Frequentlyaskedquestions />
      <Testimonial />

      {/* Offcanvas for Size Chart */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">Size Chart</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          {/* Table displaying the size options */}
          <MeasurementTable productId={productId}/>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
